import axios from '@axios'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    calendarOptions: [],
    selectedCalendars: [],
    prevStartDate: null,
    isNewMonth: false,
  },
  getters: {},
  mutations: {
    SET_CALENDAR_OPTIONS(state, val) {
      state.calendarOptions = val
    },
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
    SET_PREV_START_DATE(state, val) {
      state.prevStartDate = val
    },
    SET_IS_NEW_MONTH(state, val) {
      state.isNewMonth = val
    },
  },
  actions: {
    fetchEvents(ctx, { calendars, info }) {
      const start = moment(info.start)
      let newMonth = false
      let start_date = start.format('YYYY-MM-DD')
      if (start.date() !== 1) {
        const nextMonth = moment(start).add(1, 'months')
        start_date = nextMonth.startOf('month').format('YYYY-MM-DD')
      }
      if (start_date !== ctx.state.prevStartDate) {
        newMonth = true
        ctx.state.prevStartDate = start_date
        ctx.state.isNewMonth = true
      } else {
        ctx.state.isNewMonth = false
      }

      return new Promise((resolve, reject) => {
        axios
          .get('calendar', {
            params: {
              start_date,
              period: 'month',
              selected_softs: '1,2,3,4',
            },
          })
          .then((response) => resolve({ response, newMonth }))
          .catch((error) => reject(error))
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/calendar/events', { event })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/calendar/events/${event.id}`, { event })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
  },
}
