<template>
  <div class="sidebar-wrapper d-flex flex-column flex-grow-1">
      <date-picker
          v-if="betaFeature"
          v-model="chosenDate"
          reset-button
          :persistent="true"
          :inline="true"
          :no-header="true"
          :calendar-button="false"
          class="pt-1"
      />
    <div class="p-2 pl-3">
      <h5 class="app-label section-label mb-1">
        <span class="align-middle">{{i18nT(`Calendars`)}}</span>
      </h5>
      <b-form-checkbox
        v-model="checkAll"
        class="mb-1"
      >
        {{i18nT(`All`)}}
      </b-form-checkbox>
      <b-form-group>
        <b-form-checkbox-group
          v-model="selectedCalendars"
          name="event-filter"
          stacked
        >
          <b-form-checkbox
            v-for="item in calendarOptions"
            :key="item.label"
            name="event-filter"
            :value="item.value"
            class="mb-1"
            :class="`custom-control-${item.color}`"
          >
            {{ i18nT(item.label) }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>


    </div>


  </div>
</template>

<script>
import { BFormGroup, BFormCheckboxGroup, BFormCheckbox } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useCalendarSidebar from './useCalendarSidebar'
import DatePicker from '@core/components/DatePicker.vue'

export default {
  directives: {
    Ripple
  },
  components: {
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    DatePicker,
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      require: true
    }
  },
  mounted() {
    this.checkAll = true
  },
  setup() {
    const {
      calendarOptions,
      selectedCalendars,
      checkAll
    } = useCalendarSidebar()

    return {
      calendarOptions,
      selectedCalendars,
      checkAll
    }
  },
    data() {
        return {
            chosenDate: null,
        }
    },
    watch: {
        chosenDate(val) {
            this.$emit('change', val);
        }
    }
}
</script>

<style lang="scss">
.check-re {
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #5a5aff;
    background-color: #5a5aff;
  }
}
.check-on {
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #26a7ff;
    background-color: #26a7ff;
  }
}
.check-hr {
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #28c77a;
    background-color: #28c77a;
  }
}
.check-en {
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #ff9f43;
    background-color: #ff9f43;
  }
}
</style>
